// 'use strict';

import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "0.9em",
      },
    },
  },
});

export default theme;
