import React, { useEffect, useState } from "react";
import subsrt from "subsrt";
// import axios from "axios";
export const localHost = "http://localhost:5000/";

export function OutsideClick(ref) {
  const [isClicked, setIsClicked] = useState();
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsClicked(true);
      } else {
        setIsClicked(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
  return isClicked;
}

export function timedVidUrl(vidUrl, time) {
  var secDecimal = Math.floor(Number(time));
  let timedUrl = "";

  if (vidUrl.includes("embed")) {
    timedUrl = vidUrl + "?start=" + secDecimal; //Youtube의 embeded 영상은 이런 형식으로 해줘야함
  } else {
    timedUrl = vidUrl + "#t=" + secDecimal;
  }
  return timedUrl;
}

export function ParseFromSrt(f) {
  var pattern =
    /^(\d{2}:\d{2}:\d{2}[.,]\d{3})\s-->\s(\d{2}:\d{2}:\d{2}[.,]\d{3})\n(.*(?:\r?\n(?!\r?\n).*)*)/gm;

  if (typeof f != "string")
    throw new Error("Sorry, Parser accept string only.");

  var result = [];
  if (f == null) return;

  f = f.replace(/\r\n|\r|\n/g, "\n");

  var matches;
  while ((matches = pattern.exec(f)) != null) {
    result.push(toLineObj(matches));
  }
  return result;
}
var toLineObj = function (group) {
  return {
    startTime: group[1],
    endTime: group[2],
    text: group[3],
  };
};

export function FormattedTimeToSec(time) {
  var hourMinSec = time.split(":");

  var hour = Number(hourMinSec[0]) * 3600;
  var min = Number(hourMinSec[1]) * 60;
  var sixtySec = Number(hourMinSec[2].slice(0, 2));
  var milSec = hourMinSec[2].slice(-3);
  var decimalSec = hour + min + sixtySec;
  var fullSec = Number(decimalSec + "." + milSec);

  return fullSec;
}
export function format(seconds) {
  if (isNaN(seconds)) {
    return `00:00`;
  }
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = date.getUTCSeconds().toString().padStart(2, "0");
  if (hh) {
    return `${hh}:${mm.toString().padStart(2, "0")}:${ss}`;
  }
  return `${mm}:${ss}`;
}

export function fullFormat(seconds) {
  if (isNaN(seconds)) {
    return `00:00:00`;
  }
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = date.getUTCSeconds().toString().padStart(2, "0");

  if (hh === 0) {
    return `00:${mm.toString().padStart(2, "0")}:${ss}`;
  } else if (hh < 10) {
    const zeroH = "0" + hh;
    return `${zeroH}:${mm.toString().padStart(2, "0")}:${ss}`;
  } else {
    return `${hh}:${mm.toString().padStart(2, "0")}:${ss}`;
  }
}

export function getVideoUrl(url) {
  let match =
    url.match(
      /^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtube\.com\/watch.*v=([a-zA-Z0-9_-]+)/
    ) ||
    url.match(
      /^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtu\.be\/([a-zA-Z0-9_-]+)/
    ) ||
    url.match(/^.*(youtu.be\/|v\/|e\/|u\/\w+\/|embed\/|v=)([^#\&\?]*).*/);
  if (match && match[2].length === 11) {
    return "https://www.youtube.com/embed/" + match[2] + "?showinfo=0";
  }
  if ((match = url.match(/^(?:(https?):\/\/)?(?:www\.)?vimeo\.com\/(\d+)/))) {
    // eslint-disable-line no-cond-assign
    return (
      (match[1] || "https") + "://player.vimeo.com/video/" + match[2] + "/"
    );
  }
  return url;
}

export function extractContent(s) {
  var span = document.createElement("span");
  span.innerHTML = s;
  return span.textContent || span.innerText;
}

export function useToggle(initialState) {
  const [isToggled, setIsToggled] = React.useState(initialState);

  const setValue = React.useCallback((value) => {
    setIsToggled(value);
  }, []);
  // put [setIsToggled] into the useCallback's dependencies array
  // this value never changes so the callback is not going to be ever re-created
  const toggle = React.useCallback(
    () => setIsToggled((state) => !state),
    [setIsToggled]
  );

  return [isToggled, toggle, setValue];
}

export const sortPbfLine = (line) => {
  const timeStartIndex = line.indexOf("=") + 1;
  const timeEndIndex = line.indexOf("*");
  const secOfBm = line.slice(timeStartIndex, timeEndIndex);
  if (secOfBm.length > 3) {
    var sortedSec =
      secOfBm.substring(0, secOfBm.length - 3) + "." + secOfBm.slice(-3);
  } else {
    return;
  }

  const textStartIndex = line.indexOf("*") + 1;
  const textEndIndex = line.indexOf("*2800000048000000");
  let textOfBm = line.slice(textStartIndex, textEndIndex);
  textOfBm = textOfBm.replace(/\\/g, "\\\\");
  textOfBm = textOfBm.replace(/"/g, '\\"');

  return { sortedSec, textOfBm };
};

export const pbfToEbmContent = (loadedFile) => {
  const lines = loadedFile.split(/\r\n|\n/); //모든 행을 나눠서 array로 만들기

  const lengthOfBm = lines.length - 3; // pbf파일의 마지막 3줄은 비어있으므로 제외하기
  let ebmContent = "";

  for (var i = 1; i < lengthOfBm; i++) {
    const { sortedSec, textOfBm } = sortPbfLine(lines[i]);
    const ebmUnit = `{"time":${sortedSec},"note":"${textOfBm}"}`;

    ebmContent += ebmUnit;

    if (i < lengthOfBm - 1) {
      ebmContent += ",\n";
    }
  }
  const ebmFile = `[${ebmContent}]`;
  return ebmFile;
};
export const pbfToQlContent = (loadedFile, newVideoUrl) => {
  const lines = loadedFile.split(/\r\n|\n/); //모든 행을 나눠서 array로 만들기

  const lengthOfBm = lines.length - 3; // pbf파일의 마지막 3줄은 비어있으므로 제외하기
  let JSONContent = "";

  for (var i = 1; i < lengthOfBm; i++) {
    const { sortedSec, textOfBm } = sortPbfLine(lines[i]);
    var secDecimal = Math.floor(Number(sortedSec));

    const formattedTime = fullFormat(sortedSec);
    let timedUrl = "";

    if (newVideoUrl.includes("embed")) {
      timedUrl = newVideoUrl + "?start=" + secDecimal;
    } else {
      timedUrl = newVideoUrl + "#t=" + secDecimal;
    }

    const JSONUnit = `{"attributes":{"background":"transparent","color":"#1155cc","link":"${timedUrl}"},"insert":"${formattedTime}"},{"attributes":{"background":"transparent"},"insert":": ${textOfBm}"},{"insert":"\\n"}`;

    JSONContent += JSONUnit;

    if (i < lengthOfBm - 1) {
      JSONContent += ",\n"; //Quill에서 만들어진 일을 보면 모든 내용이 한 줄(row)이지만 보기 쉽게 하기 위해서 행을 구분해 줘도 잘작동됨
    }
  }
  const JSONFile = `[${JSONContent}]`;
  return JSONFile;
};

export const fetchUri = async (etUri) => {
  // console.log("reqqq Parrrrram: :" + etUri);
  const response = await fetch(localHost + etUri);
  // const response = await fetch(
  //   `https://et-server.onrender.com/{etUri}`
  // );
  const data = await response.json();
  // console.log(etfData);
  return data;
};
export const sortedUri = (uri) => {
  const sortedUri = uri
    .replace(/^"?(.*?)"?$/, "$1")
    .replace(/%22/g, "")
    .replace(/%20/g, " ")
    .replace(/\\/g, "/");
  return sortedUri;
};
export const locatToParamStr = (location, param) => {
  const sortedLocat = location.split(`?${param}=`)[1];
  console.log(sortedLocat);
  const sortedParam = sortedUri(sortedLocat);
  return sortedParam;
};

export const isUrl = (uri) => {
  const webUrl = ["http://", "https://", "www."];
  if (webUrl.some((word) => uri.startsWith(word))) return true;
  return false;
};

export const fileExtension = (filename) => {
  const fileExtensionInfo = /[.]/.exec(filename)
    ? /[^.]+$/.exec(filename)
    : undefined;

  const fileExtension = fileExtensionInfo[0];
  return fileExtension;
};

export const subtitlesToTs = (fileContents, fileExtension) => {
  var subsToTs = [];

  if (fileExtension === "srt" || fileExtension === "vtt") {
    var srtArray = ParseFromSrt(fileContents);
    for (const arrUnit of srtArray) {
      var fullSecOfStart = FormattedTimeToSec(arrUnit.startTime).toString();

      const tsUnit = {
        time: fullSecOfStart,
        note: arrUnit.text,
      };
      subsToTs.push(tsUnit);
    }
  } else {
    var subtitleType = subsrt.detect(fileContents);

    if (subtitleType == null) return;

    var options = { verbose: true };
    srtArray = subsrt.parse(fileContents, options);
    for (const arrUnit of srtArray) {
      fullSecOfStart = (arrUnit.start / 1000).toString();

      const bmUnit = {
        time: fullSecOfStart,
        note: arrUnit.text,
      };
      subsToTs.push(bmUnit);
    }
  }
  return subsToTs;
};

export const bmTS2QlContent = (bmTs, newVideoUrl) => {
  var deltaObjectArr = [];

  bmTs.forEach((unit) => {
    var linkStartIndex = unit.note ? unit.note.indexOf('<a href="') : null;

    var linkEndIndex = unit.note ? unit.note.indexOf("</a>") : null;

    if (linkStartIndex === 0) {
      var addressEndIndex = unit.note.indexOf('"', linkStartIndex + 10);
      var addressFromNote = unit.note.slice(
        linkStartIndex + 9,
        addressEndIndex
      );
      var trimStartIndex = unit.note.indexOf(">", linkStartIndex + 1);

      var linkText = unit.note.slice(trimStartIndex + 1, linkEndIndex);

      var remnantText = unit.note.substring(linkEndIndex + 4);

      deltaObjectArr.push(
        {
          insert: fullFormat(unit.time),
          attributes: { link: timedVidUrl(newVideoUrl, unit.time) },
        },
        { insert: ": " },
        {
          insert: linkText,
          attributes: { link: addressFromNote },
        },
        { insert: remnantText },
        { insert: "\n" }
      );
    } else {
      unit.note = unit.note ? unit.note : "";

      deltaObjectArr.push(
        {
          insert: fullFormat(unit.time),
          attributes: { link: timedVidUrl(newVideoUrl, unit.time) },
        },
        { insert: ": " + unit.note },
        { insert: "\n" }
      );
    }
  });

  const qlJSONContent = { ops: deltaObjectArr };
  return qlJSONContent;
};

export const isFileExist = (filepath) => {
  fetch(filepath)
    .then((response) => {
      if (response.status === 200) {
        console.log("File exists!");
      } else {
        console.log("File does not exist.");
      }
    })
    .catch((error) => {
      console.error("Error checking file existence:", error);
    });
};
export const checkSubFiles = (videoFilePath) => {
  const extensions = ["ets", "vtt"];
  for (const extension of extensions) {
    const subFilePath = videoFilePath.replace(/\.\w+$/, `.${extension}`);
    if (isFileExist(subFilePath)) {
      return extension;
    }
  }
  return null;
};
