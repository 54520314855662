import React, {
  useLayoutEffect,
  useCallback,
  useState,
  useRef,
  useEffect,
} from "react";

// Context
import { usePlayState } from "./EvolToyContext.js";

//Drag and resize
import Controls from "./components/Controls";

//TextEditor
import "quill/dist/quill.snow.css";

//React Quill
import QuillEditor from "./components/editor/QuillEditor";

//Draggable, resizable
import MainMenu from "./components/MainMenu.js";
import Bookmarks from "./components/Bookmarks.js";
import Transcript from "./components/Transcript.js";
import { getVideoUrl, useToggle } from "./utils.js";

import Tooltip from "@material-ui/core/Tooltip";
import screenfull from "screenfull";

import { useParams } from "react-router-dom";

//TextEditor part

const SAVE_INTERVAL_MS = 5000;

// let count = 0;

function EvolToy() {
  const boxRef = useRef(null);
  const mMBtnRef = useRef(null);

  // Quill
  const [quill, setQuill] = useState();
  const docTitleRef = useRef("Untitled");

  //Quill 파일 업로드 관련 일단은 쓸모가 없지만 나중을 위해서
  const [files, setFiles] = useState([]);

  // Context

  const {
    playerRef,
    playerContainerRef,
    isBmFocused,
    toggleFocusBm,
    mMOpen,
    toggleMMOpen,
    setMMOpen,
    controlsRef,
    mainBox,
    setMainBox,
    newVideoUrl,
    quillTitle,
    setQuillTitle,
    bookmarks,
    transcript,
    aBLoops,
    theme,
    setTheme,
  } = usePlayState();

  //TextEditor Part
  const { file: fileUrl } = useParams();
  // const fileUrl = this.props.match.params.fileUrl;
  const qlRetain = useRef(1);

  // console.log("fileurl from Evoltoy.js:" + fileUrl);

  // Authentication Part
  const docCreatorId = useRef();
  const allowedUsers = useRef();

  //Drag and resize part

  const saveToServer = (e) => {
    e.stopPropagation();
    document.getElementById("savebtn").click();
  };

  const mainBoxChange = (e) => {
    e.stopPropagation();
    switch (mainBox) {
      case "QL":
        setMainBox("BM");
        break;
      case "BM":
        setMainBox("TS");
        break;
      case "TS":
        setMainBox("QL");
        break;

      default:
        break;
    }
  };
  const addVideomark = () => {
    let embedUrl = getVideoUrl(playerRef.current.props.url);

    const vmUrl =
      embedUrl + "#t=" + Math.floor(playerRef.current.getCurrentTime());

    if (qlRetain.current === 2) {
      qlRetain.current = 1;
    }

    var vmDeltaObject = {
      ops: [{ retain: qlRetain.current }, { insert: { video: vmUrl } }],
    };
    if (vmUrl != null) {
      quill.updateContents(vmDeltaObject);
      // socket.emit("send-changes", vmDeltaObject);

      quill.setSelection(qlRetain.current + 2);
    }
  };

  var delayDebounceFn;
  const isFirstRunDoc = useRef(true);
  const onEditorChangeDelta = (qlDelta) => {
    if (isFirstRunDoc.current === true) {
      isFirstRunDoc.current = false;
      return;
    }
    if (quill == null) return;

    clearTimeout(delayDebounceFn);

    delayDebounceFn = setTimeout(() => {
      const defaultObj = '{"ops":[{"insert":"\\n"}]}';
      const qlData = quill.getContents();
      const qlContent = JSON.stringify(qlData);
      if (qlContent === defaultObj) return;

      localStorage.setItem("qlContent", qlContent);
    }, SAVE_INTERVAL_MS);
  };

  const isFirstRunTitle = useRef(true);
  useEffect(() => {
    if (isFirstRunTitle.current) {
      isFirstRunTitle.current = false;
      return;
    }
    if (quillTitle === "Untitled") return;
    localStorage.setItem("qlTitle", quillTitle);
  }, [quillTitle]);

  const isFirstRunUrl = useRef(true);
  useEffect(() => {
    if (isFirstRunUrl.current) {
      isFirstRunUrl.current = false;
      return;
    }
    if (newVideoUrl === "") return;
    localStorage.setItem("vidUrl", newVideoUrl);
  }, [newVideoUrl]);

  const isFirstRunBm = useRef(true);
  useEffect(() => {
    if (isFirstRunBm.current) {
      isFirstRunBm.current = false;
      return;
    }
    if (!bookmarks || bookmarks.length === 0) return;
    const bmContent = JSON.stringify(bookmarks);
    localStorage.setItem("bmContent", bmContent);
  }, [bookmarks]);

  const isFirstRunTs = useRef(true);
  useEffect(() => {
    if (isFirstRunTs.current) {
      isFirstRunTs.current = false;
      return;
    }
    if (!transcript || transcript.length === 0) return;
    const tsContent = JSON.stringify(transcript);
    localStorage.setItem("tsContent", tsContent);
  }, [transcript]);

  const isFirstRunAb = useRef(true);
  useEffect(() => {
    if (isFirstRunAb.current) {
      isFirstRunAb.current = false;
      return;
    }
    if (!aBLoops || aBLoops.length === 0) return;
    const aBContent = JSON.stringify(aBLoops);
    localStorage.setItem("aBContent", aBContent);
  }, [aBLoops]);

  const onEditorChangeHtml = (Html) => {};

  const onEditorChangeSource = (source) => {};

  const onEditorChangeCtrl = useCallback((q) => {
    const editor = q.getEditor();
    // q.disable(); //예전 QuillJS에 있던 것인데, React-quill에서는 에러남....
    setQuill(editor);
    // console.log(q);
  }, []);

  const onFilesChange = (files) => {
    setFiles(files);
  };

  //Swipeable Main Menu handlers

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        boxRef.current &&
        mMBtnRef.current &&
        !boxRef.current.contains(event.target) &&
        !mMBtnRef.current.contains(event.target)
      ) {
        setMMOpen(false);
      }
    }
    document.addEventListener("dblclick", handleClickOutside);
    return () => {
      document.removeEventListener("dblclick", handleClickOutside);
    };
  }, [boxRef]);

  const docTitle = (e) => {
    e.stopPropagation();
    e.preventDefault();

    var documentTitle = docTitleRef.current.value;
    if (documentTitle) {
      setQuillTitle(documentTitle);
      quill.focus();
    }
  };

  const themeToggler = (e) => {
    e.stopPropagation();
    theme === "light" ? setTheme("dark") : setTheme("light");
  };
  const isMobile = window.matchMedia("(max-width: 600px)").matches;

  return (
    <div>
      <div className="func-btns">
        <Tooltip title="Menu">
          <button
            ref={mMBtnRef}
            onClick={(e) => {
              toggleMMOpen();
            }}
            className="aside-btn"
            style={{ right: 3 }}
          >
            M
          </button>
        </Tooltip>
        <Tooltip title="Light Mode(L) - Dark Mode(D)">
          <button
            onClick={(e) => themeToggler(e)}
            className="aside-btn"
            style={{ right: 54 }}
          >
            {theme === "light" ? "D" : "L"}
          </button>
        </Tooltip>
        <Tooltip title="Save as a EvolToy file">
          <button
            onClick={(e) => saveToServer(e)}
            className="aside-btn"
            style={{ right: 102 }}
          >
            S
          </button>
        </Tooltip>
        <Tooltip title="Toggle Transcript(T) -> Quill(Q) -> Bookmarks(B)">
          <button
            onClick={(e) => mainBoxChange(e)}
            className="aside-btn"
            style={{ right: 153 }}
          >
            {mainBox === "QL" ? "B" : mainBox === "BM" ? "T" : "Q"}
          </button>
        </Tooltip>
        <Tooltip title="Focus">
          <button
            onClick={toggleFocusBm}
            className={
              mainBox === "BM" || mainBox === "TS" ? "aside-btn" : "hidden"
            }
            style={
              isBmFocused
                ? { right: 204, backgroundColor: "#ffe793" }
                : { right: 204 }
            }
          >
            F
          </button>
        </Tooltip>
      </div>
      <aside ref={boxRef} className={mMOpen ? "mm-open" : "mm-close"}>
        <MainMenu quill={quill} />
      </aside>
      <aside
        className={mainBox === "BM" || mainBox === "TS" ? "bm-open" : "hidden"}
        style={isMobile ? { height: "50%" } : {}}
      >
        {mainBox === "BM" ? (
          <Bookmarks
            docCreatorId={docCreatorId}
            allowedUsers={allowedUsers}
            isMobile={isMobile}
            isBmFocused={isBmFocused}
          />
        ) : mainBox === "TS" ? (
          <Transcript
            docCreatorId={docCreatorId}
            allowedUsers={allowedUsers}
            isMobile={isMobile}
            isBmFocused={isBmFocused}
          />
        ) : (
          ""
        )}
      </aside>
      <div className="main-video">
        <Controls
          ref={controlsRef}
          docCreatorId={docCreatorId}
          allowedUsers={allowedUsers}
          onVideomark={addVideomark}
          isMobile={isMobile}
        />
      </div>
      <form onSubmit={docTitle}>
        {isMobile ? (
          <input
            className="doc-title"
            style={{ width: "36%", left: 0 }}
            type="text"
            placeholder={quillTitle}
            ref={docTitleRef}
          />
        ) : (
          <input
            className="doc-title"
            type="text"
            placeholder={quillTitle}
            ref={docTitleRef}
          />
        )}
      </form>
      <div className={mainBox === "QL" ? "container" : "hidden"}>
        <QuillEditor
          onEditorChangeHtml={onEditorChangeHtml}
          onEditorChangeDelta={onEditorChangeDelta}
          onEditorChangeSource={onEditorChangeSource}
          onEditorChangeCtrl={onEditorChangeCtrl}
          onFilesChange={onFilesChange}
        />
      </div>
    </div>
  );
}

export default EvolToy;
