import React from "react";
import { usePlayState } from "../EvolToyContext.js";
import { fullFormat, timedVidUrl } from "../utils";

export default function Transcript(props) {
  const { isMobile } = props;
  const { newVideoUrl, playerRef, transcript, ctrlsShow, toggleCtrlsShow } =
    usePlayState();

  return (
    <div>
      {isMobile === false ? (
        <span style={{ display: "inline-block", height: 48, width: 64 }}></span>
      ) : (
        ""
      )}
      <table className="ts-table">
        <tbody>
          {transcript.map((ts, index) => (
            <tr
              key={index}
              id={`tsId${index}`}
              style={{ margin: 0, padding: 2 }}
            >
              <td
                onClick={() => {
                  if (!ctrlsShow) toggleCtrlsShow();
                  setTimeout(function () {
                    if (playerRef.current == null) return;
                    playerRef.current.seekTo(ts.time);
                  });
                }}
                elevation={3}
              >
                <h3>
                  <a
                    href={timedVidUrl(newVideoUrl, ts.time)}
                    target="inner-video"
                  >
                    {fullFormat(ts.time)}
                  </a>
                  <span>: </span>
                  {(ts.note && ts.note.indexOf("http://") === 0) ||
                  (ts.note && ts.note.indexOf("https://") === 0) ? (
                    <a href={ts.note} rel="noopener noreferrer" target="_blank">
                      {ts.note}
                    </a>
                  ) : ts.note && ts.note.indexOf("<a href=") === 0 ? (
                    <span dangerouslySetInnerHTML={{ __html: ts.note }}></span>
                  ) : (
                    <span>{ts.note}</span>
                  )}
                </h3>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
