import React from "react";
import { usePlayState } from "../EvolToyContext.js";
import { fullFormat, timedVidUrl } from "../utils";

export default function Bookmarks(props) {
  const { isMobile } = props;
  const {
    newVideoUrl,
    playerRef,
    bookmarks,
    setBookmarks,
    ctrlsShow,
    toggleCtrlsShow,
  } = usePlayState();

  const editBm = (event, index) => {
    event.stopPropagation();
    const bmNoteEdit = window.prompt("Note...", bookmarks[index].note);
    if (bmNoteEdit === null) return;

    const newBms = [...bookmarks];
    newBms[index].note = bmNoteEdit;
    setBookmarks(newBms);

    // socket.emit("send-bm-edit", {
    //   bmIndex: index,
    //   bmNote: bmNoteEdit,
    // });
  };

  const deleteBm = (event, index) => {
    event.stopPropagation();
    if (!window.confirm("Are you sure you want to delete it?")) return;

    const newBms = [...bookmarks];
    newBms.splice(index, 1);
    setBookmarks(newBms);
  };

  return (
    <div>
      {isMobile === false ? (
        <span style={{ display: "inline-block", height: 48, width: 64 }}></span>
      ) : (
        ""
      )}
      <table className="bm-table">
        <tbody>
          {bookmarks.map((bookmark, index) => (
            <tr
              key={index}
              id={`bmId${index}`}
              style={{ margin: 0, padding: 2 }}
            >
              <td
                onClick={() => {
                  if (!ctrlsShow) toggleCtrlsShow();
                  setTimeout(function () {
                    if (playerRef.current == null) return;
                    playerRef.current.seekTo(bookmark.time);
                  });
                }}
                elevation={3}
              >
                <h3>
                  <a
                    href={timedVidUrl(newVideoUrl, bookmark.time)}
                    target="inner-video"
                  >
                    {fullFormat(bookmark.time)}
                  </a>
                  <span>: </span>
                  {(bookmark.note && bookmark.note.indexOf("http://") === 0) ||
                  (bookmark.note && bookmark.note.indexOf("https://") === 0) ? (
                    <a
                      href={bookmark.note}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {bookmark.note}
                    </a>
                  ) : bookmark.note &&
                    bookmark.note.indexOf("<a href=") === 0 ? (
                    <span
                      dangerouslySetInnerHTML={{ __html: bookmark.note }}
                    ></span>
                  ) : (
                    <span>{bookmark.note}</span>
                  )}

                  <button
                    className="tiny"
                    onClick={(event) => editBm(event, index)}
                  >
                    E
                  </button>
                  <button
                    className="tiny"
                    onClick={(event) => deleteBm(event, index)}
                  >
                    X
                  </button>
                </h3>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
