import React, { useRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  timedVidUrl,
  fullFormat,
  fetchUri,
  localHost,
  sortedUri,
  locatToParamStr,
  isUrl,
  pbfToEbmContent,
  pbfToQlContent,
  fileExtension,
  subtitlesToTs,
  bmTS2QlContent,
  checkSubFiles,
} from "../utils";
import DonateButton from "./DonateButton";
import { usePlayState } from "../EvolToyContext";
import Tooltip from "@material-ui/core/Tooltip";

export default function MainMenu(props) {
  const { quill } = props;

  const newUrl = useRef(null);

  //Save file

  const doFileDownload = useRef();
  const doFileUpload = useRef();
  const doDocFileUpload = useRef();
  const doBmFileUpload = useRef();
  const doABFileUpload = useRef();
  const doTsFileUpload = useRef();
  const saveFileBtn = useRef();

  const {
    setMMOpen,
    newVideoUrl,
    setNewVideoUrl,
    bookmarks,
    setBookmarks,
    quillTitle,
    setQuillTitle,
    transcript,
    setTranscript,
    aBLoops,
    setABLoops,
    aBPointRef,
  } = usePlayState();

  const loadEtContent = (etContent) => {
    if (!etContent.title || etContent.title === "Untitled")
      localStorage.removeItem("qlTitle");
    setQuillTitle(etContent.title);

    if (!etContent.videoUrl || etContent.videoUrl === "")
      localStorage.removeItem("vidUrl");
    setNewVideoUrl(etContent.videoUrl);
    aBPointRef.current.a = null;
    aBPointRef.current.b = null;

    if (!etContent.bookmarks || Object.keys(etContent.bookmarks).length === 0)
      localStorage.removeItem("bmContent");
    setBookmarks(etContent.bookmarks);

    if (!etContent.transcript || Object.keys(etContent.transcript).length === 0)
      localStorage.removeItem("tsContent");
    setTranscript(etContent.transcript);

    if (!etContent.aBLoops || Object.keys(etContent.aBLoops).length === 0) {
      localStorage.removeItem("aBContent");
    }
    setABLoops(etContent.aBLoops);

    if (!etContent.quillData || etContent.quillData === [])
      localStorage.removeItem("qlContent");
    if (quill == null) return;

    quill.disable();
    quill.setContents(etContent.quillData);
    quill.enable();
  };

  const fetchEtf = async (etUri) => {
    const data = await fetchUri(etUri);
    // console.log(data);
    const etContent = JSON.parse(data.etfData);
    if (etContent) loadEtContent(etContent);
  };

  const handleNewUrl = (uri) => {
    const sortedParam = sortedUri(uri);
    const uriExt = sortedParam.split(".").pop().toLowerCase();
    if (sortedParam.toLowerCase().includes(".etf")) {
      if (isUrl(sortedUri)) {
        fetchEtf("api?url=" + sortedParam);
      } else {
        fetchEtf("localetf?filepath=" + sortedParam);
      }
      return;
    } else if (/webm|mp4|mkv|ogg/.test(uriExt)) {
      if (isUrl(sortedUri)) {
        setNewVideoUrl(sortedUri);
      } else {
        setNewVideoUrl(localHost + "localvid?filepath=" + sortedUri);
      }
      return;
    }

    setNewVideoUrl(sortedUri);
    aBPointRef.current.a = null;
    aBPointRef.current.b = null;
  };
  const handleFocus = () => {
    const input = newUrl.current;
    const value = input.value;
    const index = value.lastIndexOf(input.selectionStart);

    input.setSelectionRange(0, index);
  };

  const newVideoAddress = (e) => {
    e.preventDefault();

    handleNewUrl(newUrl.current.value);
    document.getElementById("ctrl-box").focus();
    setMMOpen(false);
  };

  const saveFile = () => {
    const qlFilename = window.prompt("Name of the file...", quillTitle);

    if (qlFilename === null) return;

    let fileName = qlFilename + ".etf";

    const qlContent = JSON.stringify(quill.getContents());
    const bmContent = JSON.stringify(bookmarks);
    const tsContent = JSON.stringify(transcript);
    const aBContent = JSON.stringify(aBLoops);

    const etContent = `{
      "title": "${quillTitle}",
      "videoUrl": "${newVideoUrl}",
      "quillData": ${qlContent},
      "bookmarks": ${bmContent},
      "transcript": ${tsContent},
      "aBLoops": ${aBContent}
    }`;

    const etfBlob = new Blob([etContent]);

    const fileDownloadUrl = URL.createObjectURL(etfBlob);

    doFileDownload.current.href = fileDownloadUrl;
    doFileDownload.current.setAttribute("download", `${fileName}`);
  };

  const saveToFile = (event) => {
    event.preventDefault();
    saveFile();
    doFileDownload.current.click();
  };

  const saveDocToFile = (event) => {
    event.preventDefault();

    const qlFilename = window.prompt("Name of the file...", quillTitle);

    if (qlFilename === null || quill === null) return;

    let fileName = qlFilename + ".JSON";

    const qlContent = JSON.stringify(quill.getContents());

    const qlBlob = new Blob([qlContent]);
    const fileDownloadUrl = URL.createObjectURL(qlBlob);

    doFileDownload.current.href = fileDownloadUrl;
    doFileDownload.current.setAttribute("download", `${fileName}`);

    doFileDownload.current.click();
  };

  const saveBMToFile = (event) => {
    event.preventDefault();

    const bmFilename = window.prompt("Name of the file...", quillTitle);

    if (bmFilename === null) return;

    let fileName = bmFilename + ".ebm";

    const bmContent = JSON.stringify(bookmarks);

    const bmBlob = new Blob([bmContent]);
    const bmDownloadUrl = URL.createObjectURL(bmBlob);

    doFileDownload.current.href = bmDownloadUrl;
    doFileDownload.current.setAttribute("download", `${fileName}`);

    doFileDownload.current.click();
  };

  const saveABToFile = (event) => {
    event.preventDefault();

    const aBFilename = window.prompt("Name of the file...", quillTitle);

    if (aBFilename === null) return;

    let fileName = aBFilename + ".eab";

    const aBContent = JSON.stringify(aBLoops);

    const aBBlob = new Blob([aBContent]);
    const aBDownloadUrl = URL.createObjectURL(aBBlob);

    doFileDownload.current.href = aBDownloadUrl;
    doFileDownload.current.setAttribute("download", `${fileName}`);

    doFileDownload.current.click();
  };

  const saveTsToFile = (event) => {
    event.preventDefault();

    const tsFilename = window.prompt("Name of the file...", quillTitle);

    if (tsFilename === null) return;

    let fileName = tsFilename + ".txt";

    let tsContent = "";

    transcript.forEach((ts) => {
      let tsTxtLine = `${fullFormat(ts.time)}: ${ts.note} \n`;
      tsContent += tsTxtLine;
    });

    const tsBlob = new Blob([tsContent]);
    const tsDownloadUrl = URL.createObjectURL(tsBlob);

    doFileDownload.current.href = tsDownloadUrl;
    doFileDownload.current.setAttribute("download", `${fileName}`);

    doFileDownload.current.click();
  };

  const loadFile = (event) => {
    if (
      !window.confirm(
        "Loading a file will overwrite current Document, Bookmarks, Transcript, AB Loops and you can't undo it. \n Are you sure you want to load a file?"
      )
    )
      return;
    event.preventDefault();
    doFileUpload.current.click();
  };

  const loadDocFile = (event) => {
    if (
      !window.confirm(
        "Loading a file will overwrite current Document and you can't undo it. \n Are you sure you want to load a file?"
      )
    )
      return;
    event.preventDefault();
    doDocFileUpload.current.click();
  };

  const pbfToDoc = (evt) => {
    var pbfFilename = "";
    let exportFilename = "";

    const pbfFiles = evt.target.files;
    if (pbfFiles.length === 0) return;

    const pbfFile = pbfFiles[0];

    pbfFilename = pbfFile.name;
    exportFilename = pbfFilename.substring(0, pbfFilename.lastIndexOf("."));

    const reader = new FileReader();
    reader.onload = (e) => {
      const loadedFile = e.target.result;

      const JSONFile = pbfToQlContent(loadedFile, newVideoUrl);

      const JSONBlob = new Blob([JSONFile]);
      const fileDownloadUrl = URL.createObjectURL(JSONBlob);

      doFileDownload.current.href = fileDownloadUrl;
      doFileDownload.current.setAttribute("download", `${exportFilename}.JSON`);

      doFileDownload.current.click();
    };
    reader.onerror = (e) => alert(e.target.error.name);
    reader.readAsText(pbfFile);

    evt.target.value = "";
  };

  const pbfToEbm = (evt) => {
    var pbfFilename = "";
    let exportFilename = "";

    const pbfFiles = evt.target.files;
    if (pbfFiles.length === 0) return;

    const pbfFile = pbfFiles[0];

    pbfFilename = pbfFile.name;
    exportFilename = pbfFilename.substring(0, pbfFilename.lastIndexOf("."));

    const reader = new FileReader();
    reader.onload = (e) => {
      const loadedFile = e.target.result;

      const ebmFile = pbfToEbmContent(loadedFile);

      const ebmBlob = new Blob([ebmFile]);
      const fileDownloadUrl = URL.createObjectURL(ebmBlob);

      doFileDownload.current.href = fileDownloadUrl;
      doFileDownload.current.setAttribute("download", `${exportFilename}.ebm`);

      doFileDownload.current.click();
    };
    reader.onerror = (e) => alert(e.target.error.name);
    reader.readAsText(pbfFile);

    evt.target.value = "";
  };

  const subsFileToTs = (evt) => {
    const fileObj = evt.target.files[0];
    const reader = new FileReader();
    const filename = fileObj.name;
    const fileExt = fileExtension(filename);
    let fileloaded = (e) => {
      // e.target.result is the file's content as text
      var fileContents = e.target.result;

      var subsToTs = subtitlesToTs(fileContents, fileExt);

      setTranscript(subsToTs);
    };

    // Mainline of the method
    fileloaded = fileloaded.bind(this);
    reader.onload = fileloaded;
    reader.readAsText(fileObj);

    evt.target.value = "";
  };

  const uploadBmFile = (event) => {
    if (
      !window.confirm(
        "Loading a file will overwrite current Bookmarks and you can't undo it. \n Are you sure you want to load a file?"
      )
    )
      return;
    event.preventDefault();
    doBmFileUpload.current.click();
  };

  const uploadABFile = (event) => {
    if (
      !window.confirm(
        "Loading a file will overwrite current AB Loops and you can't undo it. \n Are you sure you want to load a file?"
      )
    )
      return;
    event.preventDefault();
    doABFileUpload.current.click();
  };

  const uploadTsFile = (event) => {
    if (
      !window.confirm(
        "Loading a file will overwrite current Transcript and you can't undo it. \n Are you sure you want to load a file?"
      )
    )
      return;
    event.preventDefault();
    doTsFileUpload.current.click();
  };
  const fetchSubs = async (etUri) => {
    const data = await fetchUri(etUri);
    console.log(data);
    if (data.etsData) {
      const etsContent = JSON.parse(data.etsData);
      setTranscript(etsContent);
    } else if (data.vttData) {
      const vttContent = data.vttData;
      const etsContent = subtitlesToTs(vttContent, "vtt");
      setTranscript(etsContent);
    }
  };
  useEffect(() => {
    const currLocation = window.location.href;
    if (currLocation.includes("?etfurl=")) {
      const fileUrl = currLocation.split("?etfurl=")[1];

      if (fileUrl) {
        fetchEtf("api?url=" + fileUrl);
      }
    }
    if (currLocation.includes("?vidurl=")) {
      const vidUrl = currLocation.split("?vidurl=")[1];

      if (vidUrl) {
        setNewVideoUrl(vidUrl);
        aBPointRef.current.a = null;
        aBPointRef.current.b = null;
      }
    }
    if (currLocation.includes("?localetf=")) {
      const etfPath = locatToParamStr(currLocation, "localetf");
      if (etfPath) {
        fetchEtf("localetf?filepath=" + etfPath);
      }
    }
    if (currLocation.includes("?localvid=")) {
      const vidPath = locatToParamStr(currLocation, "localvid");

      if (vidPath) {
        fetchSubs("localsubs?filepath=" + vidPath);
        setNewVideoUrl(localHost + "localvid?filepath=" + vidPath);
        aBPointRef.current.a = null;
        aBPointRef.current.b = null;
      }
    }
    document.getElementById("urlInput").focus();
  }, []);

  const openFile = (evt) => {
    const fileObj = evt.target.files[0];
    const reader = new FileReader();

    let fileloaded = (e) => {
      // e.target.result is the file's content as text
      const fileContents = e.target.result;
      const etContent = JSON.parse(fileContents);
      loadEtContent(etContent);
      document.getElementById("etUpload").value = ""; //밑에 evt.target.value = ''에서 file을 비워주므로 필요 없지 않나???
    };

    // Mainline of the method
    fileloaded = fileloaded.bind(this);
    reader.onload = fileloaded;
    reader.readAsText(fileObj);

    evt.target.value = "";
  };

  const openDocFile = (evt) => {
    const fileObj = evt.target.files[0];
    const reader = new FileReader();

    let fileloaded = (e) => {
      // e.target.result is the file's content as text
      const fileContents = e.target.result;
      const qlJson = JSON.parse(fileContents);

      if (quill === null) return;

      quill.disable();
      quill.setContents(qlJson);
      quill.enable();

      document.getElementById("qlUpload").value = ""; //밑에 evt.target.value = ''에서 file을 비워주므로 필요 없지 않나???
    };

    // Mainline of the method
    fileloaded = fileloaded.bind(this);
    reader.onload = fileloaded;
    reader.readAsText(fileObj);

    evt.target.value = "";
  };

  const openBMFile = (evt) => {
    const fileObj = evt.target.files[0];
    const reader = new FileReader();

    let fileloaded = (e) => {
      // e.target.result is the file's content as text
      const fileContents = e.target.result;
      const bmJson = JSON.parse(fileContents);

      if (
        window.confirm(
          "Do you want to merge the bookmarks?(Duplicated ones will be deleted) If you press Cancel, current bookmarks will be deleted"
        )
      ) {
        var mergedBm = bookmarks.concat(bmJson);
        // console.log(mergedBm);
        const uniqueArray = mergedBm.reduce((unique, obj) => {
          if (!unique.some((t) => t.time === obj.time && t.note === obj.note)) {
            unique.push(obj);
          }
          return unique;
        }, []);
        uniqueArray.sort((a, b) => parseFloat(a.time) - parseFloat(b.time));
        setBookmarks(uniqueArray);
      } else {
        setBookmarks(bmJson);
      }

      document.getElementById("bmUpload").value = "";
    };

    // Mainline of the method
    fileloaded = fileloaded.bind(this);
    reader.onload = fileloaded;
    reader.readAsText(fileObj);

    evt.target.value = ""; //file 비워주기?
  };

  const openABFile = (evt) => {
    const fileObj = evt.target.files[0];
    const reader = new FileReader();

    let fileloaded = (e) => {
      const fileContents = e.target.result;
      const aBJson = JSON.parse(fileContents);
      setABLoops(aBJson);
      document.getElementById("aBUpload").value = "";
    };

    // Mainline of the method
    fileloaded = fileloaded.bind(this);
    reader.onload = fileloaded;
    reader.readAsText(fileObj);

    evt.target.value = ""; //file 비워주기?
  };

  const openTSFile = (evt) => {
    const fileObj = evt.target.files[0];
    const reader = new FileReader();

    let fileloaded = (e) => {
      // e.target.result is the file's content as text
      const fileContents = e.target.result;
      const tsJson = JSON.parse(fileContents);

      setTranscript(tsJson);

      document.getElementById("tsUpload").value = "";
    };

    // Mainline of the method
    fileloaded = fileloaded.bind(this);
    reader.onload = fileloaded;
    reader.readAsText(fileObj);

    evt.target.value = ""; //file 비워주기?
  };

  const handleVideoUpload = (event) => {
    if (event.target.files[0] == null) return;
    // console.log(event.target.files[0]);
    setNewVideoUrl(URL.createObjectURL(event.target.files[0]));
    aBPointRef.current.a = null;
    aBPointRef.current.b = null;
  };

  const convertBmToQl = () => {
    const qlJSONContent = bmTS2QlContent(bookmarks, newVideoUrl);
    quill.updateContents(qlJSONContent);
  };

  const convertTsToQl = () => {
    const qlJSONContent = bmTS2QlContent(transcript, newVideoUrl);
    quill.updateContents(qlJSONContent);
  };

  const loadAutoSaved = () => {
    if (localStorage.getItem("qlTitle"))
      setQuillTitle(localStorage.getItem("qlTitle"));

    if (localStorage.getItem("vidUrl"))
      setNewVideoUrl(localStorage.getItem("vidUrl"));

    if (localStorage.getItem("bmContent"))
      setBookmarks(JSON.parse(localStorage.getItem("bmContent")));

    if (localStorage.getItem("tsContent"))
      setTranscript(JSON.parse(localStorage.getItem("tsContent")));

    if (localStorage.getItem("aBContent"))
      setABLoops(JSON.parse(localStorage.getItem("aBContent")));

    if (quill == null) return;
    if (localStorage.getItem("qlContent")) {
      const qlData = JSON.parse(localStorage.getItem("qlContent"));
      quill.disable();
      quill.setContents(qlData);
      quill.enable();
    }
  };
  return (
    <div className="row">
      <Tooltip title="Evolution is fun!">
        <span>
          <Link className="brand" to="/">
            EvolToy
          </Link>
          <h3 className="by">by Torus Park</h3>
        </span>
      </Tooltip>
      <div>
        <DonateButton />
      </div>
      <Tooltip title="Save as a EvolToy file">
        <button
          onClick={saveToFile}
          id="savebtn"
          className="primary"
          ref={saveFileBtn}
        >
          Save
        </button>
      </Tooltip>
      <a className="hidden" ref={doFileDownload} />
      <Tooltip title="Load a EvolToy file">
        <button onClick={loadFile} id="loadbtn" className="primary">
          Load
        </button>
      </Tooltip>
      <input
        type="file"
        id="etUpload"
        className="hidden"
        multiple={false}
        accept=".etf"
        onChange={openFile}
        ref={doFileUpload}
      />
      <Tooltip title="Save the document as a file">
        <button onClick={saveDocToFile} className="primary">
          SvDc
        </button>
      </Tooltip>
      <a className="hidden" ref={doFileDownload} />
      <Tooltip title="Load a document file">
        <button onClick={loadDocFile} className="primary">
          LdDc
        </button>
      </Tooltip>
      <input
        type="file"
        id="qlUpload"
        className="hidden"
        multiple={false}
        accept=".json,.txt,.text,application/json,text/plain"
        onChange={openDocFile}
        ref={doDocFileUpload}
      />
      <form onSubmit={newVideoAddress}>
        <Tooltip title="To focus press I">
          <input
            type="text"
            size="25"
            id="urlInput"
            placeholder="Enter Video or ETF URL..."
            onFocus={handleFocus}
            ref={newUrl}
          />
        </Tooltip>
        <button className="primary">Fetch</button>
      </form>
      <input
        id="localVideo"
        type="file"
        className="hidden"
        onChange={handleVideoUpload}
      />
      <Tooltip title="Load a video file from local drive">
        <label className="localVid" for="localVideo">
          Local
        </label>
      </Tooltip>
      <Tooltip title="Save bookmarks as a file">
        <button onClick={saveBMToFile} className="primary">
          SvBM
        </button>
      </Tooltip>
      <a className="hidden" ref={doFileDownload} />
      <Tooltip title="Load a bookmark file">
        <button onClick={uploadBmFile} className="primary">
          LdBM
        </button>
      </Tooltip>
      <input
        type="file"
        id="bmUpload"
        className="hidden"
        multiple={false}
        accept=".ebm, .ets"
        onChange={openBMFile}
        ref={doBmFileUpload}
      />
      <Tooltip title="Save AB-loops as a file">
        <button onClick={saveABToFile} className="primary">
          SvAB
        </button>
      </Tooltip>
      <a className="hidden" ref={doFileDownload} />
      <Tooltip title="Load a AB-loops file">
        <button onClick={uploadABFile} className="primary">
          LdAB
        </button>
      </Tooltip>
      <input
        type="file"
        id="aBUpload"
        className="hidden"
        multiple={false}
        accept=".eab"
        onChange={openABFile}
        ref={doABFileUpload}
      />
      <Tooltip title="Load a transcript file">
        <button onClick={uploadTsFile} className="primary">
          LdTS
        </button>
      </Tooltip>
      <input
        type="file"
        id="tsUpload"
        className="hidden"
        multiple={false}
        accept=".ets, .ebm"
        onChange={openTSFile}
        ref={doTsFileUpload}
      />
      <input
        type="file"
        className="hidden"
        multiple={false}
        accept=".pbf"
        onChange={pbfToDoc}
        id="pbfToQuill"
      />
      <Tooltip title="Convert a PotPlayer file to a document file">
        <button
          onClick={() => document.getElementById("pbfToQuill").click()}
          className="primary"
        >
          Pbf2Dc
        </button>
      </Tooltip>
      <a className="hidden" ref={doFileDownload} />
      <Tooltip title="Convert a PotPlayer file to a bookmarks file">
        <button
          onClick={() => document.getElementById("pbfToEbm").click()}
          className="primary"
        >
          Pbf2Ebm
        </button>
      </Tooltip>
      <input
        type="file"
        className="hidden"
        multiple={false}
        accept=".pbf"
        onChange={pbfToEbm}
        id="pbfToEbm"
      />
      <a className="hidden" ref={doFileDownload} />
      <Tooltip title="Load a subtitle file to transcript">
        <button
          onClick={(e) => {
            e.stopPropagation();

            if (
              !window.confirm(
                "Loading a file will overwrite current Transcript and you can't undo it. \n Are you sure you want to load a file?"
              )
            )
              return;

            document.getElementById("subsToTs").click();
          }}
          className="primary"
        >
          SB2TS
        </button>
      </Tooltip>
      <input
        type="file"
        className="hidden"
        multiple={false}
        accept=".vtt, .lrc, .smi, .ssa, .ass, .sub, .srt, .sbv, .json"
        onChange={subsFileToTs}
        id="subsToTs"
      />
      <Tooltip title="Convert a transcript file to a text file">
        <button onClick={saveTsToFile} className="primary">
          TS2TX
        </button>
      </Tooltip>
      <Tooltip title="Insert transcript to document">
        <button onClick={convertTsToQl} className="primary">
          TS2DC
        </button>
      </Tooltip>
      <Tooltip title="Insert bookmarks to document">
        <button onClick={convertBmToQl} className="primary">
          BM2DC
        </button>
      </Tooltip>
      <Tooltip title="Load auto-saved file">
        <button
          onClick={(e) => {
            e.stopPropagation();
            if (!window.confirm("Do you want to restore Auto Saved file?"))
              return;

            loadAutoSaved();
          }}
          className="primary"
        >
          LDSV
        </button>
      </Tooltip>
    </div>
  );
}
