import React, { useContext, useState, useRef, useEffect } from "react";
import { useToggle } from "./utils";
import styled, { ThemeContext, ThemeProvider } from "styled-components";
import { lightTheme, darkTheme, GlobalStyle } from "./themes.js";

const PlayerContext = React.createContext();
const ThemesContext = React.createContext();

const StyledApp = styled.div`
  color: ${(props) => props.theme.fontColor};
`;

export function usePlayState() {
  return useContext(PlayerContext);
}

export function useThemesState() {
  return useContext(ThemesContext);
}

export function EvolToyProvider({ children }) {
  // Player Contexts

  const [ctrlsShow, toggleCtrlsShow] = useToggle(true);
  const [isBmFocused, toggleFocusBm] = useToggle(true);
  const [mMOpen, toggleMMOpen, setMMOpen] = useToggle(true);

  const playerRef = useRef(null);
  const playerContainerRef = useRef(null);
  const controlsRef = useRef(null);
  const aBPointRef = useRef({ a: null, b: null });

  const [theme, setTheme] = useState("dark");

  const [newVideoUrl, setNewVideoUrl] = useState("");

  // MainBox Contexts
  const [mainBox, setMainBox] = useState("BM");
  const [quillTitle, setQuillTitle] = useState("Untitled");
  const [bookmarks, setBookmarks] = useState([]);
  const [transcript, setTranscript] = useState([]);
  const [aBLoops, setABLoops] = useState([]);
  const [aBBtnDisplay, setABBtnDisplay] = useState("A");

  return (
    <PlayerContext.Provider
      value={{
        ctrlsShow,
        toggleCtrlsShow,
        mMOpen,
        toggleMMOpen,
        setMMOpen,
        isBmFocused,
        toggleFocusBm,
        playerRef,
        playerContainerRef,
        controlsRef,
        newVideoUrl,
        setNewVideoUrl,
        mainBox,
        setMainBox,
        quillTitle,
        setQuillTitle,
        bookmarks,
        setBookmarks,
        transcript,
        setTranscript,
        aBLoops,
        setABLoops,
        aBPointRef,
        aBBtnDisplay,
        setABBtnDisplay,
        theme,
        setTheme,
      }}
    >
      <ThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
        <GlobalStyle />
        <StyledApp>{children}</StyledApp>
      </ThemeProvider>
    </PlayerContext.Provider>
  );
}
