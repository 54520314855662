import { createGlobalStyle } from 'styled-components';

export const lightTheme = { body: '#fff', fontColor: '#000' };

export const darkTheme = {
  link: '#70c625',
  linkDone: '#fee692',
  body: '#000',
  button: '#035e26',
  buttonTiny: '#1a2e09',
  quill: '#090909',
  tableRowNth: '#282828',
  tableRowHover: '#28470d',
  tableBorder: '#035e26',
  fontColor: '#fff',
};

export const GlobalStyle = createGlobalStyle`

a { color: ${(props) => props.theme.link} }

a:visited { color: ${(props) => props.theme.link}}

body { background-color: ${(props) => props.theme.body} }

button { background-color: ${(props) => props.theme.button}; color: ${(props) =>
  props.theme.fontColor} }

button.tiny { background-color: ${(props) =>
  props.theme.buttonTiny}; border: 0.1rem solid ${(props) =>
  props.theme.tableBorder} }

.bm-open { background-color: ${(props) => props.theme.body} }

.container .ql-editor { background-color: ${(props) => props.theme.quill} }

input,
select,
textarea
{ background-color: ${(props) => props.theme.body}; color: ${(props) =>
  props.theme.fontColor}  }

table tbody tr:nth-of-type(odd) { background-color: ${(props) =>
  props.theme.tableRowNth} }

table tbody tr:nth-of-type(odd):hover { background-color: ${(props) =>
  props.theme.tableRowHover} }

table tr { border: 0.1rem solid ${(props) => props.theme.tableBorder}}
  
table thead, table th { background-color: ${(props) => props.theme.body} }

table tr { background-color: ${(props) => props.theme.body} }

table tr:hover { background-color: ${(props) => props.theme.tableRowHover} }

.log-box { background-color : ${(props) => props.theme.body} }

`;
