import React from "react";
import EvolToy from "./EvolToy";

import { BrowserRouter, Route } from "react-router-dom";

import { EvolToyProvider } from "./EvolToyContext";

import { ThemeProvider } from "@material-ui/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import theme from "./theme.js";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <EvolToyProvider>
          <Route path="/" component={EvolToy} exact />
          <Route path="/:file" component={EvolToy} />
        </EvolToyProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
