import React, { useEffect } from "react";
import { usePlayState } from "../EvolToyContext.js";

import { timedVidUrl, fullFormat } from "../utils.js";
import { Grid } from "@material-ui/core";

export default function ABLoops(props) {
  // console.log("ABLoops.js rendered");
  const {
    newVideoUrl,
    playerRef,
    ctrlsShow,
    toggleCtrlsShow,
    aBLoops,
    setABLoops,
    setABBtnDisplay,
    aBPointRef,
  } = usePlayState();

  const aBPoints = aBPointRef.current;

  const aBLoopClick = (event, index) => {
    event.stopPropagation();
    aBPoints.a = aBLoops[index].a;
    aBPoints.b = aBLoops[index].b;
    const formattedA = fullFormat(aBPoints.a);
    const formattedB = fullFormat(aBPoints.b);
    setABBtnDisplay(`${formattedA}\n${formattedB}`);
    playerRef.current.seekTo(aBPoints.a);
  };

  const editAB = (event, index) => {
    event.stopPropagation();
    const aBNoteEdit = window.prompt("Note...", aBLoops[index].note);
    if (aBNoteEdit === null) return;

    const newABs = [...aBLoops];
    newABs[index].note = aBNoteEdit;
    setABLoops(newABs);
  };

  const deleteAB = (event, index) => {
    event.stopPropagation();

    if (!window.confirm("Are you sure you want to delete it?")) return;

    const newABs = [...aBLoops];
    newABs.splice(index, 1);
    setABLoops(newABs);
  };

  return (
    <div>
      <Grid
        container
        direction="row"
        alignItems="center"
        justify="space-between"
      >
        <Grid item xs={12}>
          <table className="ab-table">
            <tbody>
              {aBLoops.map((aBloop, index) => (
                <tr key={index} style={{ margin: 0, padding: 2 }}>
                  <td
                    onClick={(event) => {
                      if (!ctrlsShow) toggleCtrlsShow();
                      setTimeout(function () {
                        if (playerRef.current == null) return;
                        aBLoopClick(event, index);
                      });
                    }}
                    elevation={3}
                  >
                    <h3>
                      <a
                        href={timedVidUrl(newVideoUrl, aBloop.a)}
                        target="inner-video"
                      >
                        {`${fullFormat(aBloop.a)} ► ${fullFormat(aBloop.b)}`}
                      </a>
                      <span>: </span>
                      {(aBloop.note && aBloop.note.indexOf("http://") === 0) ||
                      (aBloop.note && aBloop.note.indexOf("https://") === 0) ? (
                        <a
                          href={aBloop.note}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          {aBloop.note}
                        </a>
                      ) : aBloop.note &&
                        aBloop.note.indexOf("<a href=") === 0 ? (
                        <span
                          dangerouslySetInnerHTML={{ __html: aBloop.note }}
                        ></span>
                      ) : (
                        <span>{aBloop.note}</span>
                      )}

                      <button
                        className="tiny"
                        onClick={(event) => editAB(event, index)}
                      >
                        E
                      </button>
                      <button
                        className="tiny"
                        onClick={(event) => deleteAB(event, index)}
                      >
                        X
                      </button>
                    </h3>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Grid>
      </Grid>
    </div>
  );
}
