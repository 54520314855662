import React, {
  forwardRef,
  useState,
  useRef,
  useEffect,
  useCallback,
} from "react";
// import screenfull from "screenfull";

import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import FeaturedVideoIcon from "@material-ui/icons/FeaturedVideo";
import FastRewindIcon from "@material-ui/icons/FastRewind";
import FastForwardIcon from "@material-ui/icons/FastForward";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";
import Slider from "@material-ui/core/Slider";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";
import VolumeUp from "@material-ui/icons/VolumeUp";
import VolumeDown from "@material-ui/icons/VolumeDown";
import VolumeMute from "@material-ui/icons/VolumeOff";
import AddBoxIcon from "@material-ui/icons/AddBox";
import FullScreen from "@material-ui/icons/Fullscreen";

import { usePlayState } from "../EvolToyContext.js";
import {
  extractContent,
  format,
  fullFormat,
  getVideoUrl,
  useToggle,
} from "../utils.js";

import ReactPlayer from "react-player";
import { Rnd } from "react-rnd";

import OpenWithIcon from "@material-ui/icons/OpenWith";
import FeaturedPlayListIcon from "@material-ui/icons/FeaturedPlayList";
import MinimizeIcon from "@material-ui/icons/Minimize";
import Draggable from "react-draggable";
import { Link } from "react-router-dom";
import ABLoops from "./ABLoops.js";

const useStyles = makeStyles((theme) => ({
  controlsWrapper: {
    // top: '90%',
    // '@media (max-width:600px)': { top: '64%' },
    left: 0,
    visibility: "visible",
    position: "fixed",
    overflow: "auto",
    resize: "both",
    right: 0,
    top: 72,
    paddingRight: 9,
    paddingLeft: 9,
    width: "100%",
    borderRadius: 9,
    background: "rgba(100,144,0,0.3)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },

  button: {
    margin: theme.spacing(1),
  },
  controlIcons: {
    color: "#bbb",
    opacity: "81%",
    margin: 0,
    padding: 0,
    fontSize: 33,
    transform: "scale(0.9)",
    "&:hover": {
      color: "#fff",
      transform: "scale(1)",
    },
  },

  volumeSlider: {
    width: 63,
    padding: 0,
    marginTop: 9,
    marginBottom: 3,
    left: 0,
    display: "block",
  },
}));

const PrettoSlider = withStyles({
  root: {
    height: 0,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);
function ValueLabelComponent(props) {
  const { children, open, value } = props;

  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}

const Controls = forwardRef(
  ({ onVideomark, docCreatorId, allowedUsers, isMobile }, ref) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const [currentTs, setCurrentTs] = useState("");
    const [timeDisplayFormat, setTimeDisplayFormat] = useState("normal");

    const [aBLoopsShow, toggleABLoopsShow] = useToggle(true);

    const height = window.innerHeight;
    const width = window.innerWidth;

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const {
      ctrlsShow,
      toggleCtrlsShow,
      isBmFocused,
      mMOpen,
      toggleMMOpen,
      playerRef,
      playerContainerRef,
      newVideoUrl,
      mainBox,
      bookmarks,
      setBookmarks,
      transcript,
      aBLoops,
      setABLoops,
      aBPointRef,
      aBBtnDisplay,
      setABBtnDisplay,
      theme,
    } = usePlayState();

    const [playState, setPlayState] = useState({
      pip: false,
      playing: false,
      controls: false,
      light: false,

      muted: false,
      played: 0,
      duration: 0,
      playbackRate: 1.0,
      volume: 1,
      loop: false,
      seeking: false,
    });

    const { playing, light, muted, loop, playbackRate, pip, played, volume } =
      playState;

    const currentTimeFocus =
      playerRef && playerRef.current && isBmFocused === true
        ? playerRef.current.getCurrentTime()
        : null;

    const prevBmIndex = useRef(0);
    const prevTsIndex = useRef(0);

    const jumpRate = 5;

    useEffect(() => {
      if (isBmFocused === true && mainBox === "BM") {
        if (playerRef.current.getCurrentTime()) {
          bookmarks.find((o, i) => {
            const bmTime = Number(o.time);
            const bmDecimalTime = bmTime.toFixed(0);

            if (
              currentTimeFocus &&
              bmDecimalTime === currentTimeFocus.toFixed(0)
            ) {
              if (prevBmIndex.current !== null) {
                document.getElementById(
                  `bmId${prevBmIndex.current}`
                ).style.backgroundColor = "";
              }
              document
                .getElementById(`bmId${i}`)
                .scrollIntoView({ block: "center", behavior: "smooth" });

              document.getElementById(`bmId${i}`).style.backgroundColor =
                theme === "light" ? "#ffe793" : "#035e26";

              prevBmIndex.current = i;

              return true; // stop searching
            }
          });
        }
      }

      if (isBmFocused === true && mainBox === "TS") {
        if (playerRef.current.getCurrentTime()) {
          transcript.find((o, i) => {
            const tsTime = Number(o.time);
            const tsDecimalTime = tsTime.toFixed(0);

            if (
              currentTimeFocus &&
              tsDecimalTime === currentTimeFocus.toFixed(0)
            ) {
              if (prevTsIndex.current !== null) {
                document.getElementById(
                  `tsId${prevTsIndex.current}`
                ).style.backgroundColor = "";
              }
              document
                .getElementById(`tsId${i}`)
                .scrollIntoView({ block: "center", behavior: "smooth" });

              document.getElementById(`tsId${i}`).style.backgroundColor =
                theme === "light" ? "#ffe793" : "#035e26";

              prevTsIndex.current = i;

              return true; // stop searching
            }
          });
        }
      }

      if (transcript !== null && ctrlsShow === true) {
        if (playerRef.current.getCurrentTime()) {
          transcript.find((o, i) => {
            const tsTime = Number(o.time);
            const tsDecimalTime = tsTime.toFixed(0);

            if (
              currentTimeFocus &&
              tsDecimalTime === currentTimeFocus.toFixed(0)
            ) {
              setCurrentTs(o.note);

              return true; // stop searching
            }
          });
        }
      }
    }, [currentTimeFocus, playState, mainBox]);

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleProgress = (changeState) => {
      // if (count > 3) {
      //   controlsRef.current.style.visibility = 'hidden';
      //   count = 0;
      // }
      // if (controlsRef.current.style.visibility == 'visible') {
      //   count += 1;
      // }
      if (!playState.seeking) {
        if (ctrlsShow || isBmFocused) {
          setPlayState({ ...playState, ...changeState });
        }
      }
    };

    function handlePlayPause(e) {
      // e.stopPropagation();

      setPlayState({ ...playState, playing: !playState.playing });
    }

    function handleRewind() {
      playerRef.current.seekTo(playerRef.current.getCurrentTime() - jumpRate);
    }

    function handleFastForward() {
      playerRef.current.seekTo(playerRef.current.getCurrentTime() + jumpRate);
    }

    function handleSeekChange(e, newValue) {
      setPlayState({ ...playState, played: parseFloat(newValue / 100) });
    }

    function handleSeekMouseDown(e) {
      e.stopPropagation();
      setPlayState({ ...playState, seeking: true });
    }

    function handleSeekMouseUp(e, newValue) {
      setPlayState({ ...playState, seeking: false });
      if (!playerRef.current) return;
      playerRef.current.seekTo(newValue / 100, "fraction");
    }

    function handleDuration(duration) {
      setPlayState({ ...playState, duration });
    }

    function handleVolumeSeekDown(e, newValue) {
      setPlayState({
        ...playState,
        seeking: false,
        volume: parseFloat(newValue / 100),
      });
    }
    function handleVolumeChange(e, newValue) {
      setPlayState({
        ...playState,
        volume: parseFloat(newValue / 100),
        muted: newValue === 0 ? true : false,
      });
    }

    const handlePlaybackRate = (rate) => {
      setPlayState({ ...playState, playbackRate: rate });
    };

    const handleMute = () => {
      setPlayState({ ...playState, muted: !playState.muted });
    };
    const handleDisplayFormat = () => {
      setTimeDisplayFormat(
        timeDisplayFormat === "normal" ? "remaining" : "normal"
      );
    };

    const currentTime =
      playerRef && playerRef.current
        ? playerRef.current.getCurrentTime()
        : "00:00";

    const duration =
      playerRef && playerRef.current
        ? playerRef.current.getDuration()
        : "00:00";
    const elapsedTime =
      timeDisplayFormat === "normal"
        ? format(currentTime)
        : `-${format(duration - currentTime)}`;

    const totalDuration = format(duration);

    //A-B Loop
    const currentABTime =
      playerRef &&
      playerRef.current &&
      aBBtnDisplay !== "A" &&
      aBBtnDisplay !== "B"
        ? playerRef.current.getCurrentTime()
        : "00:00";

    const aBPoints = aBPointRef.current;

    useEffect(() => {
      if (aBBtnDisplay !== "A" && aBBtnDisplay !== "B") {
        if (playerRef.current) {
          let secondFloor = Math.ceil(playerRef.current.getCurrentTime());
          let bFloor = Math.ceil(aBPoints.b);

          if (secondFloor >= bFloor) {
            playerRef.current.seekTo(aBPoints.a);
          }
        }
      }
    }, [currentABTime]);

    const aBLoop = () => {
      switch (aBBtnDisplay) {
        case "A":
          setABBtnDisplay("B");
          aBPoints.a = playerRef.current.getCurrentTime();
          break;

        case "B":
          aBPoints.b = playerRef.current.getCurrentTime();
          playerRef.current.seekTo(aBPoints.a);

          const formattedA = fullFormat(aBPoints.a);
          const formattedB = fullFormat(aBPoints.b);
          setABBtnDisplay(`${formattedA}\n▼\n${formattedB}`);

          break;

        default:
          setABBtnDisplay("A");
          aBPoints.a = null;
          aBPoints.b = null;
          break;
      }
    };

    const formattedPaste = useRef("");
    const formattedLink = useRef("");
    const addressFromLink = useRef("");
    const textFromLink = useRef("");
    const remainingText = useRef("");

    const ctrlBox = document.getElementById("ctrl-box");

    const playerQuery = document.querySelector("video");
    const capturedBmTime = useRef("");
    const addBookmark = () => {
      capturedBmTime.current = playerRef.current.getCurrentTime();

      if (playerQuery) playerQuery.pause();

      var bmNote = window.prompt("Note...", formattedLink.current);

      if (bmNote === null) {
        if (playerQuery) playerQuery.play();
        return;
      }

      const bookmarksCopy = [...bookmarks];

      const newBookmark = {
        time: capturedBmTime.current ? capturedBmTime.current : 0,
        note: bmNote,
      };

      bookmarksCopy.push(newBookmark);

      bookmarksCopy.sort((a, b) => parseFloat(a.time) - parseFloat(b.time));
      setBookmarks(bookmarksCopy);

      if (playerQuery) playerQuery.play();

      setPlayState({ ...playState, playing: true });
    };

    const keyPress = useCallback(
      (e) => {
        const { key, keyCode } = e;
        switch (keyCode) {
          case 32: // "Space"
            handlePlayPause();
            break;

          case 37: // "ArrowLeft"
            handleRewind();
            break;

          case 39: // "ArrowRight"
            handleFastForward();
            break;

          case 38: // "ArrowUp"
            let volumePlus = volume + 0.1;
            if (volumePlus <= 1) {
              setPlayState({
                ...playState,
                volume: parseFloat(volumePlus),
                muted: volumePlus === 0 ? true : false,
              });
            }
            break;

          case 40: //"ArrowDown"
            let volumeMinus = volume - 0.1;
            if (volumeMinus >= 0) {
              setPlayState({
                ...playState,
                volume: parseFloat(volumeMinus),
                muted: volumeMinus === 0 ? true : false,
              });
            }
            break;

          case 88: // "KeyX"
            let slowerRate = playbackRate - 0.5;
            if (slowerRate >= 0.5) {
              setPlayState({ ...playState, playbackRate: slowerRate });
            }
            break;

          case 67: // "KeyC"
            let fasterRate = playbackRate + 0.5;
            if (fasterRate <= 2) {
              setPlayState({ ...playState, playbackRate: fasterRate });
            }
            break;

          case 65: // "KeyA"
            aBLoop();
            break;

          case 66: // "KeyB"
            addABLoop();
            break;

          case 70: // "KeyF"
            toggleFullScreen();
            break;

          case 80: // "KeyP"
            addBookmark();
            break;

          case 77: // "KeyM"
            toggleMMOpen();
            break;

          case 73: // "KeyI"
            setTimeout(() => {
              if (mMOpen) document.getElementById("urlInput").focus();
            }, 100); // the duration of the toggle animation
            break;

          case 83: // "KeyS"
            document.getElementById("savebtn").click();
            break;

          case 76: // "KeyL"
            document.getElementById("loadbtn").click();
            break;

          default:
            break;
        }
      },
      [setPlayState, aBLoop]
    );

    useEffect(() => {
      if (ctrlBox !== null) {
        ctrlBox.addEventListener("keydown", keyPress);
      }
      return () => {
        if (ctrlBox !== null) {
          ctrlBox.removeEventListener("keydown", keyPress);
        }
      };
    }, [keyPress]);

    document.addEventListener("paste", function (e) {
      var pastedData = e.clipboardData.getData("text/html");
      formattedPaste.current = pastedData;
      var linkStartIndex = formattedPaste.current.indexOf('<a href="');
      var linkEndIndex = formattedPaste.current.indexOf("</a>");
      if (linkStartIndex >= 0 && linkEndIndex >= 0) {
        // address Extraction
        var addressEndIndex = formattedPaste.current.indexOf(
          '"',
          linkStartIndex + 10
        );
        addressFromLink.current = formattedPaste.current.slice(
          linkStartIndex + 9,
          addressEndIndex
        );

        //text Extraction
        var copiedLink = formattedPaste.current.slice(
          linkStartIndex,
          linkEndIndex + 4
        );
        textFromLink.current = extractContent(copiedLink);

        var remainingPaste = formattedPaste.current.substring(linkEndIndex + 4);
        var remnantText = extractContent(remainingPaste);

        remainingText.current = remnantText.slice(0, remnantText.length - 2);
        formattedLink.current = `<a href="${addressFromLink.current}" rel="noopener noreferrer" target="_blank">${textFromLink.current}</a>${remainingText.current}`;
      }
    });

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const ctrlsShowHide = () => toggleCtrlsShow();

    // const aBLoopsShowHide = () => toggleABLoopsShow();

    const vidWidth = isMobile === false ? width / 2 : width;
    // const vidHeight = isMobile === false ? height / 2 : height / 3;

    const vidHeight =
      isMobile === false ? (width / 2 - 6) / (16 / 9) : width / (16 / 9);

    const addABLoop = () => {
      if (!aBPoints.a || !aBPoints.b) {
        window.alert("Please set the AB loop first.");
        return;
      } else {
        var aBNote = window.prompt("Note...");

        if (aBNote === null) return;

        const aBLoopsCopy = [...aBLoops];

        const newABloop = {
          a: aBPoints.a,
          b: aBPoints.b,
          note: aBNote,
        };

        aBLoopsCopy.push(newABloop);

        aBLoopsCopy.sort((a, b) => parseFloat(a.a) - parseFloat(b.b));
        setABLoops(aBLoopsCopy);
      }
    };

    const [rndSize, setRndSize] = useState({
      width: vidWidth,
      height: vidHeight,
    });
    const [rndPosition, setRndPosition] = useState({ x: 0, y: 45 });
    const [ctrlPosition, setCtrlPosition] = useState({
      x: 0,
      y: vidHeight - 27,
    });
    const [rndFull, toggleRndFull] = useToggle(false);
    const rndDragging = React.useRef(false);
    const rndResizing = React.useRef(false);
    const ctrlDragging = React.useRef(false);

    const toggleFullScreen = () => {
      // screenfull.toggle(playerContainerRef.current);
      // console.log(playerContainerRef.current);
      // var elem = document.getElementById("video");
      // if (elem.requestFullscreen) {
      //   elem.requestFullscreen();
      // } else if (elem.mozRequestFullScreen) {
      //   elem.mozRequestFullScreen();
      // } else if (elem.webkitRequestFullscreen) {
      //   elem.webkitRequestFullscreen();
      // } else if (elem.msRequestFullscreen) {
      //   elem.msRequestFullscreen();
      // }
      toggleRndFull();
      if (rndFull) {
        setRndSize({
          width: width,
          height: height,
        });
        setRndPosition({ x: 0, y: 45 });
        setCtrlPosition({ x: 0, y: height - 36 });
      } else {
        setRndSize({
          width: vidWidth,
          height: vidHeight,
        });
        setRndPosition({ x: 0, y: 45 });
        setCtrlPosition({ x: 0, y: vidHeight - 27 });
      }
    };
    const rndOnDrag = React.useCallback(
      (_, d) => {
        // 地図移動と一緒にドラッグされないようにする
        if (!rndDragging.current) {
          setRndPosition({ x: d.x, y: d.y });
        }
      },
      [rndDragging]
    );
    const rndOnResize = React.useCallback(
      (_, d) => {
        // 地図移動と一緒にドラッグされないようにする
        if (!rndResizing.current) {
          setRndSize({ x: d.x, y: d.y });
        }
      },
      [rndResizing]
    );

    const ctrlOnDrag = React.useCallback(
      (_, d) => {
        // 地図移動と一緒にドラッグされないようにする
        if (!ctrlDragging.current) {
          setCtrlPosition({ x: d.x, y: d.y });
        }
      },
      [ctrlDragging]
    );
    return (
      <div id="ctrl-box" tabIndex={-1} ref={playerContainerRef}>
        <Rnd
          size={rndSize}
          position={rndPosition}
          onDrag={rndOnDrag}
          onResize={rndOnResize}
        >
          <div
            className="ytDrag"
            style={{ width: "100%", height: vidHeight / 4 }}
          ></div>
          <ReactPlayer
            ref={playerRef}
            // width={vidWidth}
            // height={vidHeight}
            width="100%"
            height="100%"
            url={newVideoUrl}
            pip={pip}
            playing={playing}
            controls={false}
            light={light}
            loop={loop}
            playbackRate={playbackRate}
            volume={volume}
            muted={muted}
            onProgress={handleProgress}
            // config={{
            //   file: {
            //     attributes: {
            //       crossOrigin: "anonymous",
            //     },
            //   },
            // }}
          />
        </Rnd>
        <div>
          <Draggable
            handle=".ctrls-handle"
            axis={isMobile === false ? "both" : "y"}
            position={ctrlPosition}
            onDrag={ctrlOnDrag}
          >
            <div>
              <OpenWithIcon className="ctrls-handle" style={{ fontSize: 33 }} />
              <Tooltip title="FullScreen (Hotkey F)">
                <FullScreen
                  className="ctrls-full"
                  style={{ fontSize: 33 }}
                  onClick={toggleFullScreen}
                />
              </Tooltip>
              <MinimizeIcon
                className="ctrls-toggle"
                style={{ fontSize: 33 }}
                onClick={ctrlsShowHide}
              />
              <FeaturedPlayListIcon
                className="abloops-toggle"
                style={{ fontSize: 33 }}
                onClick={toggleABLoopsShow}
              />
              {ctrlsShow ? (
                <div
                  ref={ref}
                  className={classes.controlsWrapper}
                  style={{
                    width: !isMobile ? width / 2 : width,
                    height: aBLoopsShow ? height / 2.31 : 128,
                  }}
                >
                  <div>
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      justify="space-between"
                    >
                      <Grid item xs={12}>
                        <div className="ts-line1">{currentTs}</div>
                      </Grid>
                      <Grid item xs={12}>
                        <PrettoSlider
                          min={0}
                          max={100}
                          ValueLabelComponent={(props) => (
                            <ValueLabelComponent
                              {...props}
                              value={elapsedTime}
                            />
                          )}
                          aria-label="custom thumb label"
                          value={played * 100}
                          onChange={handleSeekChange}
                          onMouseDown={handleSeekMouseDown}
                          onChangeCommitted={handleSeekMouseUp}
                          onDuration={handleDuration}
                        />
                      </Grid>
                      <Tooltip title="Rewind (Hotkey <-)">
                        <IconButton
                          onClick={handleRewind}
                          className={classes.controlIcons}
                          aria-label="rewind"
                        >
                          <FastRewindIcon fontSize="inherit" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Play - Pause (Hotkey Space)">
                        <IconButton
                          onClick={handlePlayPause}
                          className={classes.controlIcons}
                          aria-label="play"
                        >
                          {playing ? (
                            <PauseIcon fontSize="inherit" />
                          ) : (
                            <PlayArrowIcon fontSize="inherit" />
                          )}
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Fast Forward (Hotkey ->)">
                        <IconButton
                          onClick={handleFastForward}
                          className={classes.controlIcons}
                          aria-label="forward"
                        >
                          <FastForwardIcon fontSize="inherit" />
                        </IconButton>
                      </Tooltip>
                      <Grid item>
                        <Tooltip title="Volume (Hotkey Up-Arrow, Down-Arrow)">
                          <IconButton
                            onClick={handleMute}
                            className={`${classes.controlIcons} ${classes.volumeButton}`}
                          >
                            {muted ? (
                              <VolumeMute fontSize="medium" />
                            ) : volume > 0.5 ? (
                              <VolumeUp fontSize="medium" />
                            ) : (
                              <VolumeDown fontSize="medium" />
                            )}
                          </IconButton>
                        </Tooltip>
                      </Grid>
                      <Grid item>
                        <Slider
                          min={0}
                          max={100}
                          value={muted ? 0 : volume * 100}
                          onChange={handleVolumeChange}
                          aria-labelledby="input-slider"
                          className={classes.volumeSlider}
                          onMouseDown={handleSeekMouseDown}
                          onChangeCommitted={handleVolumeSeekDown}
                        />
                        <Button
                          variant="text"
                          className={classes.controlIcons}
                          onClick={handleDisplayFormat}
                        >
                          <Typography
                            variant="subtitle2"
                            style={{
                              color: "#fff",
                              marginTop: 3,
                            }}
                            // style={{ display: 'block' }}
                          >
                            {elapsedTime}/{totalDuration}
                          </Typography>
                        </Button>
                      </Grid>
                      <Tooltip title="Playback Rate (Hotkey X, C)">
                        <Grid item>
                          <div className="popover">
                            <Link to="#">
                              <IconButton
                                onClick={handleClick}
                                aria-describedby={id}
                                className={classes.controlIcons}
                                variant="text"
                              >
                                <h2>{playbackRate}X</h2>
                              </IconButton>
                            </Link>
                            <ul className="popover-content">
                              <li>
                                <Link to="#rate">
                                  {[0.5, 1, 1.5, 2].map((rate) => (
                                    <IconButton
                                      key={rate}
                                      //   onClick={() => setState({ ...state, playbackRate: rate })}
                                      onClick={() => handlePlaybackRate(rate)}
                                      variant="text"
                                      className={classes.controlIcons}
                                    >
                                      <Typography
                                        // color={
                                        //   rate === playbackRate
                                        //     ? "secondary"
                                        //     : "inherit"
                                        // }
                                        style={{
                                          marginLeft: 3,
                                          marginRight: 3,
                                        }}
                                      >
                                        {rate}X
                                      </Typography>
                                    </IconButton>
                                  ))}
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </Grid>
                      </Tooltip>

                      <Grid item>
                        <Tooltip title="AB-Loop (Hotkey A)">
                          <IconButton
                            onClick={aBLoop}
                            className={classes.controlIcons}
                            variant="text"
                          >
                            {aBBtnDisplay === "A" || aBBtnDisplay === "B" ? (
                              <h1 className="abLoopBtn">{aBBtnDisplay}</h1>
                            ) : (
                              <pre style={{ fontSize: 15 }}>{aBBtnDisplay}</pre>
                            )}
                          </IconButton>
                        </Tooltip>
                      </Grid>
                      <Grid item>
                        <Tooltip title="Bookmark (Hotkey P)">
                          <IconButton
                            className={classes.controlIcons}
                            aria-label="bookmark"
                            onClick={addBookmark}
                          >
                            <BookmarkIcon className={classes.controlIcons} />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                      <Grid item>
                        <Tooltip title="Videomark">
                          <IconButton
                            className={classes.controlIcons}
                            aria-label="videomark"
                            onClick={onVideomark}
                          >
                            <FeaturedVideoIcon
                              className={classes.controlIcons}
                            />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </div>
                  <div
                    className={aBLoopsShow ? "abloops-btns-wrapper" : "hidden"}
                  >
                    <Tooltip title="Add AB-Loops(Hotkey B)">
                      <AddBoxIcon
                        className="add-loop-btn"
                        style={{ fontSize: 36 }}
                        onClick={addABLoop}
                      />
                    </Tooltip>
                  </div>
                  {aBLoopsShow ? (
                    <Grid item xs={12} className="abloops-wrapper">
                      <ABLoops
                        docCreatorId={docCreatorId}
                        allowedUsers={allowedUsers}
                        isMobile={isMobile}
                        isBmFocused={isBmFocused}
                      />
                    </Grid>
                  ) : null}
                </div>
              ) : null}
            </div>
          </Draggable>
        </div>
      </div>
    );
  }
);

Controls.propTypes = {
  onVideomark: PropTypes.func,
};
export default Controls;
