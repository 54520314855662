import React from 'react';
import PayPalDonate from './PayPal-donate.png'

function DonateButton() {
    return (
<form action="https://www.paypal.com/donate?hosted_button_id=NCMMM42PXMB7A" method="post" target="_blank">
<input type="hidden" name="cmd" value="_s-xclick"/>
{/* <input type="hidden" name="encrypted" value=""/> */}
<input type="image" className="paypal" src={PayPalDonate} border="0" name="submit" alt="PayPal – The safer, easier way to pay online!"/>
{/* <img alt="" border="0" src="https://www.paypalobjects.com/en_GB/i/scr/pixel.gif" width="1" height="1"/> */}
</form>
    );
}

export default DonateButton;